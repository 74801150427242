export const ballsComposition = (obj) => {
  const returnArray = []
  for (const key in obj) {
    obj[key]?.forEach((item, index) => {
      if (!returnArray[index]) {
        returnArray[index] = {}
      }
      returnArray[index][key] = item
    })
  }
  return returnArray
}

export const formatLhName = (code) => {
  const mapping = [
    { name: '分分六合彩', code: 'ff6' },
    { name: '三分六合彩', code: 'sf6' },
    { name: '极速六合彩', code: 'js6' },
    { name: '香港六合彩', code: 'hk6' }
  ]
  const returnData = mapping.find(item => item.code === code)
  return returnData && returnData.name
}
